<template>
  <ContextTableCard
    title="Assessments"
    icon="file-multiple"
    :header-icons="headerIconsAssessments"
    :tableData="tableData"
    :sortField="search.sortField"
    :sortOrder="search.sortOrder"
    :page="search.page"
    :total="total"
    :row-class="setRiskColourSelected"
    @refreshData="debounceRefreshData"
    @page-change="pageChange"
    @sort="sort"
    v-model="selected"
    @headerIconClicked="assessmentHeaderClicked"
    @contextDefaultClicked="editAssessment"
    :isLoading="isLoading"
  >
    <b-table-column label="Date" field="assessmentDate" sortable v-slot="props">
      {{ shortFormat(props.row.assessmentDate) }}
    </b-table-column>
    <b-table-column label="Type" field="assessmentType" sortable v-slot="props">
      {{ props.row.typeDesc }}
    </b-table-column>
    <b-table-column label="Score" cellClass="has-text-centered" field="totalScore" sortable v-slot="props">
      <div :class="setRiskColour(props.row)">
        {{ riskScore(props.row) }}
      </div>
    </b-table-column>
    <b-table-column label="Risk" field="risk" sortable v-slot="props">
      <div :class="setRiskColour(props.row)"><b-icon :icon="icon(props.row)" size="is-small" /> {{ riskName(props.row) }}</div>
    </b-table-column>
    <b-table-column label="History" cellClass="has-text-centered" v-slot="props">
      <a v-if="riskName(props.row)" @click.stop="showHistorical(props.row.assessmentType)" title="View Historical">
        <b-icon :icon="Icons.graph" />
      </a>
    </b-table-column>
  </ContextTableCard>
</template>

<script>
import NewAssessmentTypeSelection from "@/components/dialogs/NewAssessmentTypeSelection.vue";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import HistoricalRating from "@/components/assessments/HistoricalRating.vue";

import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import AssessmentType from "@/enums/assessmentType";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  components: {
    ContextTableCard
  },
  mixins: [ContextTableMixin, IconsMixin],
  props: {
    residentId: { type: Number, required: true },
    value: { type: Number } // assessment Id, passed back
  },
  data() {
    return {
      url: "assessments",
      entity: "Assessments",
      search: {
        residentId: this.residentId,
        page: 1,
        sortField: "assessmentDate",
        sortOrder: "desc"
      }
    };
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    headerIconsAssessments() {
      const icons = [IconsMixin.headerIconRefresh()];
      if (this.hasPermission(PermissionsEnum.AssessmentAdd)) {
        icons.unshift(IconsMixin.headerIconAdd("Assessment"));
      }
      return icons;
    }
  },
  watch: {
    selected(v) {
      this.$emit("input", v.assessmentType);
    }
  },
  methods: {
    async assessmentHeaderClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$buefy.modal.open({
            parent: this,
            component: NewAssessmentTypeSelection,
            hasModalCard: true,
            trapFocus: true
          });
          break;
      }
    },
    riskName(assessment) {
      return AssessmentType.assessmentRatingDesc(assessment.assessmentType, assessment.risk);
    },
    riskScore(assessment) {
      switch (AssessmentType.RiskType[assessment.assessmentType]) {
        case AssessmentType.RiskTypeEnum.None:
          return "";
        default:
          return assessment.totalScore;
      }
    },
    setRiskColourSelected(assessment) {
      return AssessmentType.assessmentColor(assessment.assessmentType, assessment.risk, true);
    },
    setRiskColour(assessment) {
      return AssessmentType.assessmentColor(assessment.assessmentType, assessment.risk, assessment === this.selected);
    },
    icon(assessment) {
      return AssessmentType.riskIcon(AssessmentType.RiskType[assessment.assessmentType], assessment.risk);
    },
    editAssessment() {
      const row = this.selected;
      const url = AssessmentType.Url[row.assessmentType];
      this.$router.push({ name: url + ".edit", params: { id: row.assessmentId } });
    },
    showHistorical(assessmentType) {
      this.$buefy.modal.open({
        parent: this,
        component: HistoricalRating,
        hasModalCard: true,
        props: { residentId: this.residentId, assessmentType: assessmentType }
      });
    }
  }
};
</script>
