<template>
  <div>
    <XyeaFormEdit
      icon="gesture-tap"
      url="interventions/"
      entityName="Intervention"
      :form="form"
      :id="form.interventionId"
      @close="$emit('close')"
      @add="refresh"
      @update="refresh"
      :disabled="readonly"
      :dialog="true"
      :loadOnOpen="false"
    >
      <InterventionEdit v-model="form" :isSingleLine="false" :readonly="readonly" />
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import PermissionsEnum from "@/enums/permissions";
import InterventionEdit from "@/components/careplans/InterventionEdit";

export default {
  components: { XyeaFormEdit, InterventionEdit },
  props: ["intervention", "residentId"],

  data() {
    return {
      isLoading: false,
      form: this.getClearFormObject()
    };
  },
  computed: {
    readonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.CarePlanUpdate);
    }
  },
  methods: {
    getClearFormObject() {
      return {
        interventionId: this.interventionId,
        intervention: null,
        role: null
      };
    },
    refresh() {
      this.$emit("refresh");
    }
  },
  mounted() {
    if (this.intervention.interventionId) this.form = { ...this.intervention };
  }
};
</script>
