<template>
  <div>
    <XyeaFormEdit
      icon="comment"
      url="narrativeNotes/"
      entityName="Note"
      :form="form"
      :id="form.narrativeNoteId"
      @close="$emit('close')"
      @add="refresh"
      @update="refresh"
      :disabled="readonly"
      :dialog="true"
      :loadOnOpen="false"
    >
      <b-field label="Notes" horizontal>
        <SpeechToText v-model="form.notes" required :disabled="readonly" focused />
      </b-field>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import SpeechToText from "@/components/SpeechToText";
import PermissionsEnum from "@/enums/permissions";

export default {
  components: { XyeaFormEdit, SpeechToText },
  props: ["note"],
  data() {
    return {
      isLoading: false,
      form: this.getClearFormObject()
    };
  },
  computed: {
    readonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.ResidentUpdate);
    }
  },
  methods: {
    getClearFormObject() {
      return {
        residentId: this.$store.getters["resident/residentId"],
        notes: null
      };
    },
    refresh() {
      this.$emit("refresh");
    }
  },
  mounted() {
    if (this.note?.narrativeNoteId) {
      this.form = { ...this.note };
      this.form.residentId = this.$store.getters["resident/residentId"];
    }
  }
};
</script>
