<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-primary">
      <p class="modal-card-title">Select New Assessment Type</p>
      <button type="button" class="delete" @click="close" />
    </header>
    <section class="modal-card-body">
      <div class="tile is-ancestor wrap">
        <div v-for="assessmentType in assessmentTypes" :key="assessmentType.Type">
          <div class="tile is-parent">
            <div class="tile is-child box">
              <b-button class="tile" @click="select(assessmentType)" size="is-large" :icon-left="assessmentType.Icon" type="is-primary">
                <span v-if="assessmentType.CustomIcon" class="icon has-text-primary is-medium">
                  <img :src="'/img/icons/' + assessmentType.CustomIcon + '_invert.png'" />
                </span>
                <span :class="{ iconLeftPadding: !!assessmentType.CustomIcon }"> {{ assessmentType.Title }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="close">Cancel</button>
    </footer>
  </div>
</template>

<script>
import AssessmentType from "@/enums/assessmentType";

export default {
  data() {
    return {
      assessmentTypes: AssessmentType.All
    };
  },
  methods: {
    select(assessmentType) {
      this.close();
      this.$router.push({ name: assessmentType.Path + ".new" });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.iconLeftPadding {
  top: -10px;
  position: relative;
}
</style>
