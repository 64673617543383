<template>
  <XyeaFormEdit
    :icon="Icons.graph"
    url="assessments/historical/"
    :customTitle="lookupType + ' - Historical'"
    :id="residentId"
    :id2="assessmentType"
    :canSave="false"
    @close="$emit('close')"
    :dialog="true"
    :loadOnOpen="true"
    @mapServerData="mapServerData"
  >
    <line-chart v-if="chartData" :chart-data="chartData" :options="chartOptions" />
  </XyeaFormEdit>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import DatesMixin from "@/mixins/datesMixin";
import * as chartConfig from "@/components/Charts/chart.config";
import LineChart from "@/components/Charts/LineChart";
import IconsMixin from "@/mixins/iconsMixin.js";
import AssessmentType from "@/enums/assessmentType";

export default {
  mixins: [DatesMixin, IconsMixin],
  components: { XyeaFormEdit, LineChart },
  props: {
    residentId: { type: Number, required: true },
    assessmentType: { type: Number, required: true }
  },
  data() {
    return {
      lookupType: AssessmentType.Lookup[this.assessmentType],
      chartData: null,
      chartOptions: {
        ...chartConfig.chartOptionsMain,
        tooltips: {
          callbacks: {
            label: tooltipItem => {
              if (tooltipItem.datasetIndex > 0) return tooltipItem.value;
              const risk = this.chartData.datasets[tooltipItem.datasetIndex].risk[tooltipItem.index];
              return "Score: " + tooltipItem.value + " Rating: " + AssessmentType.assessmentRatingDesc(this.assessmentType, risk);
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "left-y-axis",
              type: "linear",
              position: "left",
              scaleLabel: {
                display: true,
                labelString: "Score"
              },
              ticks: {
                min: 0
              }
            }
            // {
            //   id: "right-y-axis",
            //   type: "linear",
            //   position: "right",
            //   gridLines: {
            //     display: false
            //   },
            //   ticks: {
            //     min: 0,
            //     max: 4,
            //     stepSize: 1
            //   }
            // }
          ]
        }
      }
    };
  },
  methods: {
    mapServerData(data) {
      if (data) {
        this.chartData = {
          labels: data.map(a => this.shortFormatDate(a.assessmentDate)),
          datasets: [
            {
              ...chartConfig.defaultDataSetOptions,
              data: data.map(a => a.totalScore),
              label: this.lookupType,
              yAxisID: "left-y-axis",
              risk: data.map(a => a.risk) // saved off so we cna use in tool tip
            }
            // {
            //   ...chartConfig.filltDataSetOptions,
            //   data: [1, 1, 1, 1, 1],
            //   yAxisID: "right-y-axis"
            // },
            // {
            //   data: [2, 2, 2, 2, 2],
            //   yAxisID: "right-y-axis"
            // }
          ]
        };
      }
    }
  }
};
</script>
