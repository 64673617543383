<template>
  <card-component
    title="Care Plans"
    icon="notebook-edit"
    :header-icons="headerIcons"
    @header-icons-click="careplanHeaderClicked"
    fullHeight
  >
    <!-- @change-name="onChangeName"
        @delete-node="onDel"
        @add-node="onAddNode" -->
    <vue-tree-list :model="carePlans" :addLeafNodeDisabled="true" :default-expanded="true" class="careplan">
      <!-- Needed nbsp to hide default slot using new v-slot directive -->
      <template v-slot:treeNodeIcon>&nbsp;</template>
      <template v-slot:leafNodeIcon>&nbsp;</template>

      <template v-slot:leafNameDisplay="slotProps">
        <div v-if="slotProps.model.level === carePlanLevel.CarePlan" class="categoryHeader">
          {{ slotProps.model.name }}
          <a v-if="hasCarePlanUpdate" class="header-icon is-pulled-right" @click="editCarePlan(slotProps.model.id)">
            <b-icon icon="pencil" size="is-small" />
          </a>
        </div>
        <div v-if="slotProps.model.level === carePlanLevel.Section" class="sectionHeader">{{ slotProps.model.name }}</div>
        <div v-if="slotProps.model.level === carePlanLevel.Item">
          <!-- Item Header not currently used
          <div
            v-if="slotProps.model.name"
            class="has-text-weight-bold"
            :class="setRiskColour(slotProps.model)"
            :ref="'assesment' + slotProps.model.assessmentType"
          >
            {{ slotProps.model.name }}: {{ riskLookup(slotProps.model) }} {{ slotProps.model.observation }}
          </div> -->
          <interventions :data="slotProps.model.interventions" @refresh="getCarePlans" />
        </div>
      </template>
    </vue-tree-list>
    <loading-data v-if="loadingCarePlans" />
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import NewCarePlanSelection from "@/components/dialogs/NewCarePlanSelection.vue";
import { VueTreeList, Tree } from "vue-tree-list";
import LoadingData from "@/components/LoadingData.vue";
import CarePlanEdit from "@/components/careplans/CarePlanEdit.vue";
import IconsMixin from "@/mixins/iconsMixin.js";

import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import AssessmentType from "@/enums/assessmentType";
import Interventions from "./Interventions.vue";

export default {
  mixins: [IconsMixin],
  components: {
    CardComponent,
    LoadingData,
    VueTreeList,
    Interventions
  },
  props: { residentId: { type: Number }, assessmentType: { type: Number } },
  data() {
    return {
      loadingCarePlans: false,
      carePlans: new Tree([]),
      carePlanLevel: {
        CarePlan: 1,
        Section: 2,
        Item: 3
      }
    };
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    hasCarePlanUpdate() {
      return this.hasPermission(PermissionsEnum.CarePlanUpdate);
    },
    headerIcons() {
      var icons = [IconsMixin.headerIconRefresh()];
      if (this.hasCarePlanUpdate) icons.unshift(IconsMixin.headerIconAdd("Care Plan"));

      return icons;
    }
  },
  watch: {
    assessmentType(value) {
      if (value) this.scrollTo("assesment" + value);
    },
    residentId: {
      immediate: true,
      handler: "getCarePlans"
    }
  },
  methods: {
    careplanHeaderClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$buefy.modal.open({
            parent: this,
            component: NewCarePlanSelection,
            hasModalCard: true,
            props: { residentId: this.residentId },
            events: { carePlanAdded: this.getCarePlans }
          });
          break;
        case "refresh":
          this.getCarePlans();
          break;
      }
    },
    getCarePlans() {
      if (!this.residentId) return;
      this.loadingCarePlans = true;
      this.$http
        .get("residentcareplans/view/" + this.residentId)
        .then((r) => (this.carePlans = new Tree(r.data)))
        .catch((e) => this.$alerts.showErrorAlert(e, "Error getting care plans"))
        .finally(() => (this.loadingCarePlans = false));
    },
    scrollTo(ref) {
      if (this.$refs[ref]) this.$refs[ref].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    },
    setRiskColour(row) {
      return AssessmentType.assessmentColor(row.assessmentType, row.risk);
    },
    riskLookup(row) {
      return AssessmentType.assessmentRatingDesc(row.assessmentType, row.risk);
    },
    editCarePlan(id) {
      this.$buefy.modal.open({
        parent: this,
        component: CarePlanEdit,
        hasModalCard: true,
        width: "75vw",
        props: { id: id, isResident: true },
        events: { refresh: this.getCarePlans }
      });
    }
  }
};
</script>

<style>
.careplan .vtl-tree-margin {
  margin-left: 0.5em;
}
.careplan .vtl-node-content {
  width: 100%;
}
.careplan .vtl-operation {
  display: none;
}
.careplan .vtl-leaf-node {
  margin-left: -25px;
}

.careplan .vtl-node-main:hover {
  background: inherit;
}
</style>
