<template>
  <ContextTableCard
    title="Narrative Notes"
    icon="comment-text-multiple"
    :header-icons="headerIcons"
    :tableData="tableData"
    :sortField="search.sortField"
    :sortOrder="search.sortOrder"
    :page="search.page"
    :total="total"
    @refreshData="debounceRefreshData"
    @page-change="pageChange"
    @sort="sort"
    v-model="selected"
    @headerIconClicked="headerClicked"
    @contextDefaultClicked="editNote"
    :isLoading="isLoading"
  >
    <b-table-column label="Date" field="modifiedDate" sortable v-slot="props">
      {{ shortFormatDateTime(props.row.modifiedDateLocal) }}
    </b-table-column>
    <b-table-column label="By" field="who" sortable v-slot="props">
      {{ props.row.who }}
    </b-table-column>
    <b-table-column label="Role" field="role" sortable v-slot="props">
      {{ props.row.role }}
    </b-table-column>
    <b-table-column label="Notes" field="notes" sortable v-slot="props">
      {{ props.row.notes }}
    </b-table-column>
  </ContextTableCard>
</template>

<script>
import NotesDialog from "@/components/dialogs/NotesDialog.vue";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";

import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  components: {
    ContextTableCard
  },
  mixins: [ContextTableMixin],
  props: { residentId: { type: Number, required: true } },
  data() {
    return {
      url: "narrativeNotes",
      entity: "Narrative Notes",
      search: {
        residentId: this.residentId,
        page: 1,
        sortField: "modifiedDate",
        sortOrder: "desc"
      }
    };
  },
  computed: {
    ...mapGetters(["hasPermission"])
  },
  watch: {
    residentId(v) {
      if (v !== this.search.residentId) {
        this.search.residentId = v;
        this.getData();
      }
    }
  },
  mounted() {
    const icons = [IconsMixin.headerIconRefresh()];
    if (this.hasPermission(PermissionsEnum.ResidentUpdate)) {
      icons.unshift(IconsMixin.headerIconAdd("Narrative Note"));
    }
    this.headerIcons = icons;
  },
  methods: {
    headerClicked(iconName) {
      switch (iconName) {
        case "add":
          this.selected = {};
          this.editNote();
          break;
      }
    },
    editNote() {
      this.$buefy.modal.open({
        parent: this,
        component: NotesDialog,
        hasModalCard: true,
        props: { note: this.selected, residentId: this.residentId },
        events: { refresh: this.debounceRefreshData }
      });
    }
  }
};
</script>
