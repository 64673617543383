import Vue from "vue";

// Methods common to all Resident page forms
// Ensure a resident is selected, and can use selResidentId to deep link to a particular resident
export default {
  props: ["selResidentId"],
  data() {
    return {
      residentId: null
    };
  },
  watch: {
    selResidentId() {
      this.checkSelectNewResident().then(changed => {
        if (changed) this.getData();
      });
    }
  },
  async mounted() {
    await this.checkSelectNewResident();

    if (!this.residentId) {
      // if no resident, force user to select one
      Vue.nextTick(() => {
        // done in next tick, so dirty flag gets changed to be cleared
        this.$router.push({ name: "residents", query: { redirect: this.$route.name } });
        this.$buefy.snackbar.open({ message: "Please select a Resident first" });
      });
    }
  },
  methods: {
    async getResident(residentId) {
      return await this.$http
        .get("residents", { params: { residentId: residentId } })
        .then(r => {
          return r.data[0] ? r.data[0] : Promise.reject(new Error("Resident not found or no permission"));
        })
        .catch(e => {
          this.$alerts.showErrorAlert(e, "Error loading resident");
        });
    },
    async checkSelectNewResident() {
      // residentId is the currently selected resident from the store, but for deep linking, eg from notification, we may need to change the
      // selected resident, so this is passed in via the selResidentId route param
      this.residentId = this.$store.getters["resident/residentId"];

      // if new resident passed in route, select that resident
      if (this.selResidentId > 0 && this.selResidentId !== this.residentId) {
        return await this.getResident(this.selResidentId).then(async resident => {
          if (resident) await this.$store.dispatch("resident/setResident", resident);
          else this.$router.replace({ name: "residents" });

          this.residentId = this.$store.getters["resident/residentId"];

          // return true if search param updated, so we can initiate new search
          if (this.search && this.search.residentId !== this.residentId) {
            this.search.residentId = this.residentId; // ensure search params updated also
            return true;
          }
          return false;
        });
      }
    }
  }
};
