<template>
  <div>
    <ContextTable
      :tableData="data"
      hideHeader
      @contextDefaultClicked="editIntervention"
      @menu-clicked="editIntervention"
      :isLoading="false"
      v-model="selected"
      :hideNoData="true"
    >
      <b-table-column label="Intervention" field="intervention" v-slot="props">
        {{ props.row.intervention }}
      </b-table-column>
      <b-table-column label="Role" field="role" v-slot="props">
        {{ props.row.roleName }}
      </b-table-column>
      <b-table-column label="Intervention" field="intervention" v-slot="props">
        {{ frequencyLookup[props.row.frequency] }}
      </b-table-column>
    </ContextTable>
  </div>
</template>

<script>
import FrequencyEnum from "@/enums/frequency";
import ContextTable from "@/components/ContextTable";
import InterventionEditDialog from "@/components/dialogs/InterventionEditDialog";

export default {
  props: {
    data: { type: Array, required: true }
  },
  components: { ContextTable },
  data() {
    return {
      frequencyLookup: FrequencyEnum.Lookup,
      selected: {}
    };
  },
  methods: {
    editIntervention() {
      this.$buefy.modal.open({
        parent: this,
        component: InterventionEditDialog,
        hasModalCard: true,
        props: { intervention: this.selected },
        events: { refresh: () => this.$emit("refresh") }
      });
    }
  }
};
</script>
