<template>
  <div class="modal-card">
    <form @submit.prevent="add">
      <header class="modal-card-head has-text-primary">
        <p class="modal-card-title">Select New Care Plan</p>
        <button type="button" class="delete" @click="close" />
      </header>
      <section class="modal-card-body">
        <b-field label="Select Care Plan">
          <b-select v-model="selected" required>
            <option v-for="option in careplans" :value="option.carePlanId" :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </section>

      <footer class="modal-card-foot">
        <button class="button" type="button" @click="close">Cancel</button>
        <b-button class="button is-primary" :loading="isSaving" native-type="submit">Add Care Plan</b-button>
      </footer>
    </form>
    <LoadingData v-if="isLoading" />
  </div>
</template>

<script>
import LoadingData from "@/components/LoadingData";

export default {
  components: { LoadingData },
  props: { residentId: { type: Number, required: true } },
  data() {
    return {
      careplans: [],
      isLoading: false,
      isSaving: false,
      selected: null
    };
  },
  mounted() {
    this.getCarePlans();
  },
  methods: {
    add() {
      this.isSaving = true;
      const data = { residentId: this.residentId, carePlanId: this.selected };
      this.$http
        .post("careplans/assign", data)
        .then(() => {
          this.$emit("carePlanAdded");
          this.$emit("close");
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error saving Care Plan"))
        .finally(() => (this.isSaving = false));
    },
    close() {
      this.$emit("close");
    },
    getCarePlans() {
      this.isLoading = true;
      this.$http
        .get("careplans/unassigned/" + this.residentId)
        .then(r => (this.careplans = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error getting Care Plans"))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
